const AUDIO_STATUS = {
  LOADING: "LOADING",
  PLAYING: "PLAYING",
  BROKEN: "BROKEN",
  PAUSED: "PAUSED",
  // music only
  CHANGING_SOURCE: "CHANGING_SOURCE",
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function getRandomIndex(maxIndex) {
	return Math.floor(Math.random() * maxIndex);
}

function getMusicFileUrl(name) {
	return `/assets/music/${encodeURIComponent(name)}.mp3`;
}

const DEFAULT_AIRPORT = {
	"icao": "KJFK",
	"name": "John F Kennedy International Airport",
	"city": "New York",
	"state": "New York",
	"country": "United States",
	"continent": "North America",
	"feeds": {
		"kjfk_arinc": {
			"name": "ARINC (JFK/LGA Area)",
			"feedFrequencies": {
				"ARINC (KJFK Ground Station)": "129.900",
				"ARINC (KLGA Ground Station)": "129.400"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_arinc&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_arinc"
		},
		"kjfk_del3": {
			"name": "KJFK Clearance Delivery #1",
			"feedFrequencies": {
				"JFK Clearance Delivery": "135.050"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_del3&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_del3"
		},
		"kjfk_del2": {
			"name": "KJFK Clearance Delivery #2",
			"feedFrequencies": {
				"JFK Clearance Delivery": "135.050"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_del2&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_del2"
		},
		"kjfk_co": {
			"name": "KJFK Company/Ramp",
			"feedFrequencies": {
				"Cargoitalia": "131.400",
				"Delta": "129.650",
				"JetBlue": "136.550",
				"Sun Country": "131.950",
				"United": "131.500"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_co&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_co"
		},
		"kjfk_atis": {
			"name": "KJFK D-ATIS",
			"feedFrequencies": {
				"KJFK D-ATIS": "128.725"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_atis&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_atis"
		},
		"kjfk9_s": {
			"name": "KJFK Gnd/Twr",
			"feedFrequencies": {
				"JFK Ground": "121.900",
				"JFK Tower (4L/22R, 13R/31L)": "123.900",
				"JFK Tower (4R/22L, 13L/31R)": "119.100",
				"TCA (Class B 2000ft/below within 8nm)": "125.250"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk9_s&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk9_s"
		},
		"kjfk9_gnd": {
			"name": "KJFK Ground #1",
			"feedFrequencies": {
				"JFK Ground": "121.900"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk9_gnd&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk9_gnd"
		},
		"kjfk_gnd": {
			"name": "KJFK Ground #2",
			"feedFrequencies": {
				"JFK Ground": "121.900"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_gnd&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_gnd"
		},
		"kjfk_twr": {
			"name": "KJFK Tower",
			"feedFrequencies": {
				"JFK Tower (4R/22L, 13L/31R)": "119.100"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_twr&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_twr"
		},
		"kjfk_twr3": {
			"name": "KJFK Tower #2",
			"feedFrequencies": {
				"JFK Tower (4L/22R, 13R/31L)": "123.900",
				"JFK Tower (4R/22L, 13L/31R)": "119.100"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_twr3&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_twr3"
		},
		"kjfk9_twr": {
			"name": "KJFK Tower #3",
			"feedFrequencies": {
				"JFK Tower (4L/22R, 13R/31L)": "123.900",
				"JFK Tower (4R/22L, 13L/31R)": "119.100",
				"TCA (Class B 2000ft/below within 8nm)": "125.250"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk9_twr&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk9_twr"
		},
		"kjfk_twr_1191": {
			"name": "KJFK Tower 119.1",
			"feedFrequencies": {
				"JFK Tower (4R/22L, 13L/31R)": "119.100"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_twr_1191&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_twr_1191"
		},
		"kjfk_twr_1239": {
			"name": "KJFK Tower 123.9",
			"feedFrequencies": {
				"JFK Tower (4L/22R, 13R/31L)": "123.900"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_twr_1239&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_twr_1239"
		},
		"kjfk9_app_camrn": {
			"name": "NY Approach (CAMRN/JFK) #1",
			"feedFrequencies": {
				"New York Approach (CAMRN Sector)": "128.125"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk9_app_camrn&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk9_app_camrn"
		},
		"kjfk_bw_app_camrn": {
			"name": "NY Approach (CAMRN/JFK) #2",
			"feedFrequencies": {
				"New York Approach (CAMRN Sector)": "128.125"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_bw_app_camrn&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/kjfk_bw_app_camrn"
		},
		"kjfk_118_app_camrn": {
			"name": "NY Approach (CAMRN/JFK) #3",
			"feedFrequencies": {
				"New York Approach (CAMRN Sector)": "128.125"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_118_app_camrn&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_118_app_camrn"
		},
		"kjfk_bw_app_final": {
			"name": "NY Approach (Final/JFK) #1",
			"feedFrequencies": {
				"NY Approach (JFK Final Vector)": "132.400"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_bw_app_final&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_bw_app_final"
		},
		"kjfk_118_app_final": {
			"name": "NY Approach (Final/JFK) #2",
			"feedFrequencies": {
				"NY Approach (JFK Final Vector)": "132.400"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_118_app_final&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_118_app_final"
		},
		"kjfk9_app_rober": {
			"name": "NY Approach (ROBER/JFK #1)",
			"feedFrequencies": {
				"NY Approach (ROBER Sector)": "125.700"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk9_app_rober&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk9_app_rober"
		},
		"kjfk_app_rober": {
			"name": "NY Approach (ROBER/JFK #2)",
			"feedFrequencies": {
				"NY Approach (ROBER sector)": "125.700"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_app_rober&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_app_rober"
		},
		"khpn_app_lib_n": {
			"name": "NY Dep (Liberty North) #1",
			"feedFrequencies": {
				"New York Departure (Liberty/North)": "118.175"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=khpn_app_lib_n&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/khpn_app_lib_n"
		},
		"ny_app_lib_north": {
			"name": "NY Dep (Liberty North) #2",
			"feedFrequencies": {
				"New York Departure (Liberty/North)": "118.175"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=ny_app_lib_north&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/ny_app_lib_north"
		},
		"kjfk_dep": {
			"name": "NY Departure (JFK)",
			"feedFrequencies": {
				"NY Departure (JFK)": "135.900"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=kjfk_dep&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/kjfk_dep"
		},
		"zbw_ccc1": {
			"name": "ZBW Boston Center (CLIPR32)",
			"feedFrequencies": {
				"Boston Center (CLIPR32 Sector)": "135.800",
				"Emergency/Guard": "121.500"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ccc1&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/zbw_ccc1"
		},
		"zbw_ccc2": {
			"name": "ZBW Boston Center (HTO31)",
			"feedFrequencies": {
				"Boston Center (HTO31 Sector)": "124.525",
				"Boston Center (SOUTHIE49 Sector)": "132.300"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ccc2&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/zbw_ccc2"
		},
		"zbw_ron1": {
			"name": "ZBW/ZNY/ZDC (JFK Area) #1",
			"feedFrequencies": {
				"Boston Center (IGN20 Sector 20 Kingston High)": "125.575",
				"New York Center (Sector 42 East Texas High)": "127.175",
				"New York Center (Sector 56 Kennedy High backup)": "134.375",
				"New York Center (Sector 56 Kennedy High)": "125.325",
				"Washington Center (Sector 19 Woodstown High)": "125.450"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ron1&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/zbw_ron1"
		},
		"zbw_ron2": {
			"name": "ZBW/ZNY/ZDC (JFK Area) #2",
			"feedFrequencies": {
				"New York Center (Sector 67 DIXIE Low)": "118.975",
				"Washington Center (Sector 54 Salisbury High)": "120.975",
				"Washington Center (Sector 54 Snow Hill High)": "121.375",
				"Washington Center (Sector 59 Sea Isle High)": "133.125"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ron2&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/zbw_ron2"
		},
		"zbw_ron3": {
			"name": "ZBW/ZNY/ZDC (JFK Area) #3",
			"feedFrequencies": {
				"Emergency/Guard": "121.500",
				"New York Center (Sector 66 MANTA Low)": "128.300",
				"New York Center (Sector 86 Atlantic Oceanic High)": "133.500",
				"Washington Center (Sector 51 Casino Low)": "127.700"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ron3&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/zbw_ron3"
		},
		"zbw_ron4": {
			"name": "ZBW/ZNY/ZDC (JFK Area) #4",
			"feedFrequencies": {
				"New York Center (Sector 55 Yardley Low)": "134.600",
				"New York Center (Sector 9 Westminster High)": "134.325",
				"Washington Center (Sector 58 Coyle High)": "121.025"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ron4&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/zbw_ron4"
		},
		"zbw_ron5": {
			"name": "ZNY Misc (NE PA)",
			"feedFrequencies": {
				"New York Center (Sector 34 Elk Mountain High backup)": "128.500",
				"New York Center (Sector 34 Elk Mountain High)": "132.175",
				"New York Center (Sector 35 Huguenot Low)": "132.600",
				"New York Center (Sector 49 Stoneyfork High)": "121.325"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zbw_ron5&icao=KJFK",
			"audioUrl": "https://s1-bos.liveatc.net/zbw_ron5"
		},
		"zny_col_ken": {
			"name": "ZNY Sector 56 (Kennedy)",
			"feedFrequencies": {
				"New York Center (Sector 56/Kennedy High)": "125.325"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zny_col_ken&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/zny_col_ken"
		},
		"zny_col_dix": {
			"name": "ZNY Sector 67 (Dixie)",
			"feedFrequencies": {
				"New York Center (Sector 67/Dixie Low)": "118.975"
			},
			"url": "https://www.liveatc.net/hlisten.php?mount=zny_col_dix&icao=KJFK",
			"audioUrl": "https://s1-fmt2.liveatc.net/zny_col_dix"
		}
	},
	"priority_mount": "zbw_ron1"
}

export {
  AUDIO_STATUS,
	DEFAULT_AIRPORT,
  debounce,
	getRandomIndex,
	getMusicFileUrl,
}