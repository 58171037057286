// todo: error handling for unavailable video option: https://www.youtube.com/watch?v=EIm4HvDgQCM
// const musicOptions = [
//   {
//     name: "A World After🗻",
//     url: "youtube.com/watch?v=-C9ZJfE7Vjc"
//   },
//   {
//     name: "1AM Study Session",
//     url: "youtube.com/watch?v=lTRiuFIWV54&t=15s",
//   },
//   {
//     name: "Stargazing",
//     url: "youtube.com/watch?v=j1VpCvbnwow"
//   },
//   {
//     name: "Cozy Coffee Shop Ambience",
//     url: "youtube.com/watch?v=wSEWdmmk_1o"
//   }
// ]


export const musicOptions = [
  /**
   * 1AM Study Session
   * youtube.com/watch?v=lTRiuFIWV54
   */
  {
    name: "WYS - Snowman",
    youtube: "https://www.youtube.com/watch?v=j9ziXYpFs1I",
  },
  {
    name: "Fatb - Cotton Cloud",
    youtube: "https://www.youtube.com/watch?v=7p9ydzEpePA",
  },
  {
    name: "rook1e x tender spring - the places we used to walk",
    youtube: "https://www.youtube.com/watch?v=61NMgMEMWLg",
  },
  {
    name: "imagiro - wool gloves",
    youtube: "https://www.youtube.com/watch?v=3GOylOK4e18",
  },
  {
    name: "Glimlip x Yasper - I'm sorry",
    youtube: "https://www.youtube.com/watch?v=FnZiSWohkHA",
  },
  {
    name: "mell-ø - Nova",
    youtube: "https://www.youtube.com/watch?v=oN_r557ouD8",
  },
  {
    name: "goosetaf x the fields tape x francis - carried away",
    youtube: "https://www.youtube.com/watch?v=Tz7MeFIILng",
  },
  {
    name: "j'san x epektase - snow & sand",
    youtube: "https://www.youtube.com/watch?v=5V2nGGbUE6c",
  },
  {
    name: "HM Surf - Single Phial",
    youtube: "https://www.youtube.com/watch?v=eR0XbyA3gYc",
  },
  {
    name: "cocabona x Glimlip - Drops",
    youtube: "https://www.youtube.com/watch?v=ciRuNl7PtGs",
  },
  {
    name: "Aso - espresso",
    youtube: "https://www.youtube.com/watch?v=Y4sbNEc94EE",
  },
  {
    name: "Ambulo x mell-ø - Luminescence",
    youtube: "https://www.youtube.com/watch?v=OpfHoK9VXys",
  },
  {
    name: "DLJ x BIDØ - Explorers",
    youtube: "https://www.youtube.com/watch?v=TJLmyD6Feuo",
  },
  {
    name: "Sarcastic Sounds - Wish You Were Mine",
    youtube: "https://www.youtube.com/watch?v=0Rqte6TTkHs",
  },
  {
    name: "BluntOne - Reflections",
    youtube: "https://www.youtube.com/watch?v=PhxFvu2yEI0",
  },
  {
    name: "Purrple Cat - Alone Time",
    youtube: "https://www.youtube.com/watch?v=eqmKCRU9rV8",
  },
  {
    name: "Kupla - Owls of the Night",
    youtube: "https://www.youtube.com/watch?v=S7u5XMV8WFY",
  },
  {
    name: "dryhope - Steps",
    youtube: "https://www.youtube.com/watch?v=0WWOLMcCWv4",
  },
  {
    name: "ENRA - amber",
    youtube: "https://www.youtube.com/watch?v=aU4qUCpeQAk",
  },
  {
    name: "Psalm Trees - fever",
    youtube: "https://www.youtube.com/watch?v=h5DVCtBD_YA",
  },
  {
    name: "H.1 - Circle",
    youtube: "https://www.youtube.com/watch?v=96b26qRUFcQ",
  },
  {
    name: "Pandrezz - Cuddlin",
    youtube: "https://www.youtube.com/watch?v=nSZlRU2EPYA",
  },
  {
    name: "Jordy Chandra - Late Night Call",
    youtube: "https://www.youtube.com/watch?v=bzmM_CXAnxQ",
  },
  {
    name: "less.people - Gyoza",
    youtube: "https://www.youtube.com/watch?v=FcvRGhG2FnI",
  },
  {
    name: "G Mills - Keyframe",
    youtube: "https://www.youtube.com/watch?v=EejMDX4E7yU",
  },
  {
    name: "mvdb - breeze",
    youtube: "https://www.youtube.com/watch?v=iPUUU1QPKro",
  },
  {
    name: "Mondo Loops - Lunar Drive",
    youtube: "https://www.youtube.com/watch?v=ydgz6cXaA8c",
  },
  {
    name: "Pollux Terminus - Clouded Thoughts",
    youtube: "https://open.spotify.com/album/6nakNRC332lsQ7nbDWC76b?si=pjjaWyCZQUm2AIV0OmurEA&nd=1",
  },
  {
    name: "Pollux Terminus - Elsewhere",
    youtube: "https://open.spotify.com/album/6nakNRC332lsQ7nbDWC76b?si=pjjaWyCZQUm2AIV0OmurEA&nd=1",
  },
  /**
   * lofi hip hop music - beats to sleep/chill to
   * https://www.youtube.com/watch?v=wJ5k63qazng&list=PLofht4PTcKYkwt9NTxtpfw8VPllgfe-sm
   */
  {
    name: "Flovry x tender spring - Channel 12",
    youtube: "https://www.youtube.com/watch?v=wJ5k63qazng",
  },
  {
    name: "Kurt Stewart x Lomme – Window Seat",
    youtube: "https://www.youtube.com/watch?v=9DsLlsWuD_4",
  },
  {
    name: "Kurt Stewart x Lomme – Daydreams",
    youtube: "https://www.youtube.com/watch?v=l_j1PWVzpNM",
  },
  {
    name: "Living Room – Consciousness",
    youtube: "https://www.youtube.com/watch?v=ezORl9JOqkk",
  },
  {
    name: "Living Room x Rudy Raw – Sleepmodee",
    youtube: "https://www.youtube.com/watch?v=BJHC7ZBFi-I",
  },
  {
    name: "Trxxshed – Lost In Between",
    youtube: "https://www.youtube.com/watch?v=m_qQg3gQyD8",
  },
  {
    name: "Trxxshed – Reminiscence",
    youtube: "https://www.youtube.com/watch?v=2C1nrfpqdPg",
  },
  {
    name: "Trxxshed x Clangon – Synesthesia",
    youtube: "https://www.youtube.com/watch?v=pzvBkRE-SRo",
  },
  {
    name: "Kainbeats x kudo – Wanderer",
    youtube: "https://www.youtube.com/watch?v=Dh6DghQZpUk",
  },
  {
    name: "Casiio x Sleepermane – Afterglow",
    youtube: "https://www.youtube.com/watch?v=NjDLja4ob1w",
  },
  {
    name: "Casiio x Sleepermane – Bamboo",
    youtube: "https://www.youtube.com/watch?v=7aW4OPzGvuE",
  },
  {
    name: "Casiio x Sleepermane – Cycles",
    youtube: "https://www.youtube.com/watch?v=xjceqbdXUB4",
  },
  {
    name: "Casiio x Sleepermane x ØDYSSEE – Suntai",
    youtube: "https://www.youtube.com/watch?v=EkoamWXpKbI",
  },
  {
    name: "Casiio x Sleepermane – Maya",
    youtube: "https://www.youtube.com/watch?v=1Ox6_gvTx5s",
  },
  {
    name: "Casiio x Sleepermane – Particles",
    youtube: "https://www.youtube.com/watch?v=PsA7p0Fc9mQ",
  },
  {
    name: "Casiio x Sleepermane – Magenta",
    youtube: "https://www.youtube.com/watch?v=-i-3STX7QSs",
  },
  {
    name: "Casiio x Sleepermane – Returnal",
    youtube: "https://www.youtube.com/watch?v=Jg7uJj8aEOA",
  },
  {
    name: "Casiio x Sleepermane – Distant Blue",
    youtube: "https://www.youtube.com/watch?v=kKekTf8Ljvo",
  },
  {
    name: "Casiio x Sleepermane – Mockingbird",
    youtube: "https://www.youtube.com/watch?v=V-0rAbrUb_8",
  },
  {
    name: "Casiio x Sleepermane – Atoms",
    youtube: "https://www.youtube.com/watch?v=cd1q9EWGlUI",
  },
  {
    name: "Kainbeats x Towerz – Glass Spire",
    youtube: "https://www.youtube.com/watch?v=SmioVk2rVEY",
  },
  {
    name: "Kainbeats x cxlt. – Palace in The Sky",
    youtube: "https://www.youtube.com/watch?v=76JG1KJyqGI",
  },
  {
    name: "Kainbeats x Kurt Stewart – Respite",
    youtube: "https://www.youtube.com/watch?v=3yZonj6gdt0",
  },
  {
    name: "Kainbeats x softy – Cloudy Springs",
    youtube: "https://www.youtube.com/watch?v=K7EUBOYmuyE",
  },
  {
    name: "Kainbeats x S N U G – Hillside Tree",
    youtube: "https://www.youtube.com/watch?v=c58j1P6DFXE",
  }
  /**
   * A World After🗻
   * youtube.com/watch?v=-C9ZJfE7Vjc
   */
  // {
  //   name: "Krynoze - Germination ft. Amess",
  //   youtube: "https://www.youtube.com/watch?v=yiETOOCv9U0"
  // },
  // {
  //   name: "Krynoze - Submarine Embrace ft. Hoogway",
  //   youtube: "https://www.youtube.com/watch?v=3ttfvmF7HWM"
  // },
  // {
  //   name: "Krynoze & Dimension 32 - Sediments",
  //   youtube: "https://www.youtube.com/watch?v=XZhWVz_wla8"
  // },
  // {
  //   name: "Krynoze x Hoogway - Unfamiliar Beds",
  //   youtube: "https://www.youtube.com/watch?v=DbU9B-jGGeA"
  // },
  // {
  //   name: "Krynoze - Crackling Woods (ft. Goson)",
  //   youtube: "https://www.youtube.com/watch?v=XR9sIzaib_M"
  // },
  // {
  //   name: "Krynoze - Reins ft. Sweet Medicine",
  //   youtube: "https://www.youtube.com/watch?v=aOi46PX2SQ4"
  // },
];